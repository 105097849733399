/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import {
	ATTR_PICKUP_IN_STORE,
	RA_EXCLUSIVE,
	RADIO,
	// SORT_OPTIONS,
	STRING_TRUE,
	SWATCH,
	USAGE_DEFINING,
	USAGE_DESCRIPTIVE,
	USAGE_DISPLAY,
	USAGE_OFFER,
} from './../../core/constants/catalog';

type Option = {
	key: string;
	value: string;
	translationKey: 'relevance' | 'brands' | 'name';
};

const SORT_OPTIONS = {
	defaultSortOptions: [
		{
			key: 'SN_NO_SORT',
			value: '0',
			translationKey: 'relevance',
		},
		{
			key: 'SN_SORT_BY_BRANDS',
			value: '1',
			translationKey: 'brands',
		},
		{
			key: 'SN_SORT_BY_NAME',
			value: '2',
			translationKey: 'name',
		},
	] as Option[],
	priceSortOptions: [] as Option[],
};

export {
	ATTR_PICKUP_IN_STORE,
	RA_EXCLUSIVE,
	RADIO,
	SORT_OPTIONS,
	STRING_TRUE,
	SWATCH,
	USAGE_DEFINING,
	USAGE_DESCRIPTIVE,
	USAGE_DISPLAY,
	USAGE_OFFER,
};

export const PRODUCT_LIST_DEFAULT_PAGE_SIZE = 24;
